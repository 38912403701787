import Logo from './Logo';
import FrontPage from './FrontPage';
import styles from './FrontPage.module.scss';

const App = () => {
  return (
    <FrontPage>
      <Logo />

      <header className={styles.summary}>
        Evaluate and find the best real estate fit for you!
      </header>
    </FrontPage>
  );
};

export default App;
